import React from "react";
import DefaultLayout from "../layouts/default";
import { graphql } from "gatsby";
import { EventType } from "../types/Types";
import ContentBlocks from "../components/ContentBlocks";
import EventBlock from "../components/EventBlock";

export const EventsQuery = graphql`

fragment pagesPagesFragmentEvents on CMS_pages_pages_Entry {
  id
  title
  overviewDescription
  overviewButtonText
  overviewButtonLink
  uri
  typeHandle
  backgroundColor
  backgroundColorCustom
  backgroundImage {
    id
    title
    url
    alt
  }
  overviewImage {
    id
    title
    url
    alt
  }
}

fragment contentBlocksFragmentEvents on CMS_events_events_Entry {
  contentBlocks {
    ... on CMS_contentBlocks_contentBlockMenuCardBlock_BlockType {
      ...contentBlockMenuCardBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockCatBlockRooms_BlockType {
      ...contentBlockCatBlockRoomsFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockCalendarBlock_BlockType {
      ...contentBlockCalendarBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockCategoriesTeaserBlock_BlockType {
      ...contentBlockCategoriesTeaserBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockTextBlock_BlockType {
      ...contentBlockTextBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockTitleBlock_BlockType {
      ...contentBlockTitleBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockImageBlock_BlockType {
      ...contentBlockImageBlockFragmentEvents
    }
    ... on CMS_contentBlocks_contentBlockTwoColumnsBlock_BlockType {
      ...contentBlockTwoColumnsBlockFragment
    }
    ... on CMS_contentBlocks_contentBlockNewsletterBlock_BlockType {
      ...contentBlockNewsletterBlockFragment
    }
  }
}

 fragment contentBlockMenuCardBlockFragmentEvents on CMS_contentBlocks_contentBlockMenuCardBlock_BlockType {
  id
  title
  typeHandle
  menuCardDescription
  menuCardZusatz
  menuCardMenu {
    id
    ... on CMS_menus_menus_Entry {
      id
      title
      typeHandle
      menuEntry {
        ... on CMS_menuEntry_menuEntryTitleBlock_BlockType {
          id
          typeHandle
          menuEntryTitle
        }
        ... on CMS_menuEntry_menuEntryBlock_BlockType {
          id
          typeHandle
          anzahlPersonen
          menuEntryLabel
          menuEntryPrice
          menuEntryText
        }
      }
    }
  }
}

fragment contentBlockMenuCardBlockFragmentEvents on CMS_contentBlocks_contentBlockMenuCardBlock_BlockType {
  id
  title
  typeHandle
  menuCardDescription
  menuCardZusatz
  menuCardMenu {
    id
    ... on CMS_menus_menus_Entry {
      id
      title
      typeHandle
      menuEntry {
        ... on CMS_menuEntry_menuEntryTitleBlock_BlockType {
          id
          typeHandle
          menuEntryTitle
        }
        ... on CMS_menuEntry_menuEntryBlock_BlockType {
          id
          typeHandle
          anzahlPersonen
          menuEntryLabel
          menuEntryPrice
          menuEntryText
        }
      }
    }
  }
}

fragment contentBlockCatBlockRoomsFragmentEvents on CMS_contentBlocks_contentBlockCatBlockRooms_BlockType {
  id
  title
  typeHandle
  overviewPages {
    ... on CMS_pages_pages_Entry {
      ...pagesPagesFragmentEvents
    }
  }
}

fragment eventFragmentEvents on CMS_events_events_Entry {
  id
  title
  overviewDescription
  typeHandle
  eventDateDoorsOpen
  eventdateEventBegin
  priceBookingInAdvance
  priceBoxOffice
  ticketlink
  overviewDescription
  eventDescription
  uri
  overviewImage {
    id
    title
    alt
    url
    width
    height
  }
}

fragment contentBlockCalendarBlockFragmentEvents on CMS_contentBlocks_contentBlockCalendarBlock_BlockType {
  id
  title
  typeHandle
  calendarDescription
  calendarEvents {
    id
    ... on CMS_events_events_Entry {
      ...eventFragmentEvents
    }
  }
}

fragment contentBlockCategoriesTeaserBlockFragmentEvents on CMS_contentBlocks_contentBlockCategoriesTeaserBlock_BlockType {
  id
  title
  typeHandle
  teaserBlockCategories {
    ... on CMS_pages_pages_Entry {
      ...pagesPagesFragmentEvents
    }
  }
}

fragment contentBlockTextBlockFragmentEvents on CMS_contentBlocks_contentBlockTextBlock_BlockType {
  id
  title
  typeHandle
  textBlockText
}

fragment contentBlockTitleBlockFragmentEvents on CMS_contentBlocks_contentBlockTitleBlock_BlockType {
  id
  title
  typeHandle
  titleBlockTitle
}

fragment contentBlockImageBlockFragmentEvents on CMS_contentBlocks_contentBlockImageBlock_BlockType {
  id
  title
  typeHandle
  imageBlockImage {
    id
    title
    url
    alt
  }
}

fragment contentBlockTwoColumnsBlockFragment on CMS_contentBlocks_contentBlockTwoColumnsBlock_BlockType {
  id
  title
  typeHandle
  twoColumnsText
  twoColumnsImage {
    id
    title
    url
    alt
  }
  twoColumnsImgPos
}

fragment contentBlockNewsletterBlockFragment on CMS_contentBlocks_contentBlockNewsletterBlock_BlockType {
  id
  title
  typeHandle
  newsletterBlockText
}

query ($uri: String!, $siteId: CMS_QueryArgument) {
  cms {
    entry(uri: [$uri], siteId: [$siteId]) {
      id
      title
      typeHandle
      siteId
      language
      uri
      ancestors {
        id
        title
        typeHandle
        uri
      }
      ... on CMS_events_events_Entry {
        backgroundColor
        backgroundColorCustom
        backgroundImage {
          id
          title
          alt
          url
        }
        ... eventFragmentEvents
        ... contentBlocksFragmentEvents
      }
    }
  }
}
`;

const Event: React.FC<EventType> = ({
  data: {
    cms: {
      entry: {
        id,
        title,
        overviewDescription,
        typeHandle,
        siteId,
        language,
        backgroundColor,
        backgroundColorCustom,
        backgroundImage,
        eventDateDoorsOpen,
        eventdateEventBegin,
        priceBookingInAdvance,
        priceBoxOffice,
        ticketlink,
        calendarDescription,
        eventDescription,
        overviewImage,
        contentBlocks
      }
    }
  }
}) => {

  return (
    <DefaultLayout
      id={id}
      title={title}
      description={overviewDescription}
      typeHandle={typeHandle}
      siteId={siteId}
      language={language}
      backgroundColor={backgroundColor}
      backgroundColorCustom={backgroundColorCustom}
      backgroundImage={backgroundImage}
      className="default-layout"
    >
      <EventBlock
        title={title}
        typeHandle={typeHandle}
        eventDateDoorsOpen={eventDateDoorsOpen}
        eventdateEventBegin={eventdateEventBegin}
        priceBookingInAdvance={priceBookingInAdvance}
        priceBoxOffice={priceBoxOffice}
        ticketlink={ticketlink}
        calendarDescription={calendarDescription}
        eventDescription={eventDescription}
        overviewImage={overviewImage}
        language={language}
      />
      <ContentBlocks contentBlocks={contentBlocks} id={id} title={title} typeHandle={typeHandle} language={language}/>
    </DefaultLayout>
  );
};

export default Event;
