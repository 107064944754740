import * as React from "react";
import styled from "styled-components";
import Container from "./Container";
import { EventType } from "../types/Types";
import { breakpoints, fonts } from "../styles/variables";
import ContentBlocks from "./ContentBlocks";
import moment from "moment-timezone";
import "moment/locale/de-ch";

const StyledEventBlock = styled.div`
    margin: 35px 0;
`;
const StyledEventDate = styled.p`
text-align: center;
margin: 0;
`
const StyledTitle = styled.h1`
    font: ${fonts.supertitle};
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    
    @media screen and (min-width: ${breakpoints.sm}px) {
        font-size: 60px;
    }
    `
const StyledText = styled.p`
    * {
        font: ${fonts.infotext};

    }

    p {
        font: ${fonts.text};
    }
`
const StyledTicketLinkButton = styled.div`
    text-align:right;

    a {
        border: solid 1px black;
        padding: 0.5em 1em;
        color: black;
        text-decoration: none;
        display: inline-block;
    }
`

const EventBlock: React.FC<EventType> = ({
    title,
    eventDateDoorsOpen,
    eventdateEventBegin,
    priceBookingInAdvance,
    priceBoxOffice,
    ticketlink,
    eventDescription,
    url,
    contentBlocks,
    language
}) => {

    return (
        <StyledEventBlock>
            <Container>
                <StyledEventDate>{ moment(eventdateEventBegin).isValid() ? moment(eventdateEventBegin).locale(`${language}`).format("DD.MM.YYYY") : "" }</StyledEventDate>
                <StyledTitle>{title}</StyledTitle>
                <StyledText dangerouslySetInnerHTML={{ __html: eventDescription }} />
                <StyledText>
                    {eventDateDoorsOpen && moment(eventDateDoorsOpen).isValid() && (
                        <>
                            {language === "de" && "Tür: "}
                            {language === "fr" && "Porte: "}
                            {language === "it" && "Porta: "}
                            {language === "en" && "Door: "}
                            {moment(eventDateDoorsOpen).tz('Europe/Zurich').locale(`${language}`).format("HH:mm")}
                        </>
                    )}
                    { moment(eventDateDoorsOpen).isValid() && moment(eventdateEventBegin).isValid() && <><span> / </span></> }
                    { moment(eventdateEventBegin).isValid() && (
                        <>
                            {language === "de" && "Beginn: "}
                            {language === "fr" && "Commencement: "}
                            {language === "it" && "Inizio: "}
                            {language === "en" && "Begin: "}
                            {moment(eventdateEventBegin).tz('Europe/Zurich').locale(`${language}`).format("HH:mm")}
                        </>
                    )}
                    <br />
                    {priceBookingInAdvance && (
                        <>
                            {language === "de" && "VVK: "}
                            {language === "fr" && "Prévente: "}
                            {language === "it" && "Prevendita: "}
                            {language === "en" && "Presale: "}
                            {priceBookingInAdvance} Fr.
                        </>
                    )}
                    {priceBookingInAdvance && priceBoxOffice && <><span> / </span></> }
                    {priceBoxOffice && (
                        <>
                            
                            {language === "de" && "Abendkasse: "}
                            {language === "fr" && "Billetterie: "}
                            {language === "it" && "Botteghino: "}
                            {language === "en" && "Box office: "}
                            {priceBoxOffice} Fr.
                        </>
                    )}
                    <br />
                </StyledText>
                {ticketlink && (
                    <StyledTicketLinkButton>
                        <a href="{ticketlink}">
                            {language === "de" && "Ticket kaufen"}
                            {language === "fr" && "Acheter un billet"}
                            {language === "it" && "Acquista il biglietto"}
                            {language === "en" && "Buy ticket"}
                        </a>
                    </StyledTicketLinkButton>
                )}
            </Container>
            <ContentBlocks contentBlocks={contentBlocks} id={0} title={""} typeHandle={""} language={language} />
        </StyledEventBlock>
    )

}
export default EventBlock;